import { VersionFirebase } from '../interfaces/version-firebase.interface';
import { Address } from './address.model';
import { LastGeoPosition } from './last-geoposition.model';
import { LastOrderConfig } from './order/last-order-config.model';
import { Store } from './store.model';
import { Table } from './table.model';

export class LocalConfig {
	remoteVersion: VersionFirebase;
	lastGeoPosition: LastGeoPosition;
	lastStore: { store: Store, date: number };
	lastTable: { table: Table, date: number };
	lastOrderConfig: LastOrderConfig;

	// Endereço utilizado na tela inicial
	addressToSearch: Address;
}
