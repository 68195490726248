import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EntityType } from '../enums/entity-type.enum';

// tslint:disable-next-line: typedef
declare var gtag;
declare var fbq;
@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {

	// http://meumobi.github.io/ionic/pwa/2018/04/12/using-analytics-ionic-app-pwa-native.html
	// https://stackoverflow.com/a/55222168/6031927

	constructor(private router: Router) { }

	private pixelFb: any;
	private firstPixelEntityType: EntityType;

	private gaAccounts: any = {};
	private pixelAccounts: any = {};

	init(): void {
		this.listenForRouteChanges();
		try {
			const script1: any = document.createElement('script');
			script1.async = true;
			script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaId;
			document.head.appendChild(script1);

			const script2: any = document.createElement('script');
			script2.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', '${environment.gaId}', {'send_page_view': false});
			`;
			document.head.appendChild(script2);
			this.gaAccounts[environment.gaId] = environment.gaId;
		} catch (ex) {
			console.error('Error appending google analytics');
			console.error(ex);
		}
	}

	initCustomGa(gaId: string): void {
		try {
			console.log('INIT CUSTOM GA ID: ' + gaId);
			if (this.gaAccounts[gaId] == null) {
				gtag('config', gaId);
				this.gaAccounts[gaId] = gaId;
			}
		} catch (error) {
			console.error(error);
		}
	}

	gaEvent(eventName: string, category: string, label?: string, value?: number): void {
		try {
			gtag('event', eventName, {
				event_category: category,
				event_label: label || "",
				value: value || 1
			});
		} catch (error) {
			console.error(error);
		}
	}

	exception(description: string): void {
		try {
			gtag('event', 'exception', {
				description: description,
				fatal: false
			});
		} catch (error) {
			console.error(error);
		}
	}

	trackView(path: string): void {
		try {
			// console.log(path);
			gtag('event', 'page_view', {
				page_location: path
			});
			if (this.pixelFb) {
				fbq('track', 'PageView');
			}
		} catch (error) {
			console.error(error);
		}
	}

	private listenForRouteChanges(): void {
		try {
			this.router.events.subscribe(event => {
				if (event instanceof NavigationEnd) {
					this.trackView(event.urlAfterRedirects);
				}
			});
		} catch (error) {
			console.error(error);
		}
	}

	// PIXEL FACEBOOK
	// ----------------------------------------

	initPixel(pixelId: number, entityType: EntityType): void {
		try {
			// Sem pixel configurado, e sem id para iniciar
			if (!pixelId && !this.pixelFb) {
				return;
			}
			// Se chamou para fazer init Pixel e não tem id, mas já tem um pixel configurado, 
			// removo possível script de pixel para evitar enviar dados de outros stores, ao menos que o pixel inicial seja de place
			// Se for de place, deixo incluir novos pixel id, pois iniciou pelo place
			if (!pixelId && this.pixelFb && this.firstPixelEntityType == EntityType.STORE) {
				this.removePixel();
				return;
			}
			
			if (pixelId) {
				console.log('INIT CUSTOM PIXEL ID: ' + pixelId);
				
				// É um novo pixel? 
				if (this.pixelAccounts[pixelId] == null) {
					if (!this.pixelFb) {
						this.pixelFb = document.createElement('script');
						this.pixelFb.id = "pixel-script";
						this.pixelFb.innerHTML = `
							!function(f,b,e,v,n,t,s)
							{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
							n.callMethod.apply(n,arguments):n.queue.push(arguments)};
							if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
							n.queue=[];t=b.createElement(e);t.async=!0;
							t.src=v;s=b.getElementsByTagName(e)[0];
							s.parentNode.insertBefore(t,s)}(window, document,'script',
							'https://connect.facebook.net/en_US/fbevents.js');
							fbq('init', '` + pixelId + `');
							fbq('track', 'PageView');
						`;
						document.head.appendChild(this.pixelFb);
						this.firstPixelEntityType = entityType;
					} else {
						// Se é um domínio próprio, não permite incluir novos pixelIds
						if (window.location.hostname.indexOf("shop.fastget.com.br") == -1 && window.location.hostname != "localhost") {
							return;
						}
						// - Já existe pixel de outro store -> não pode incluir pixel de mais ninguém (iniciou pelo store), precisa remover o pixel primeiro
						// - Já existe pixel de outro place -> se o novo pixel for de um store, permite, se não também precisa remover
						if (this.firstPixelEntityType == EntityType.STORE || (this.firstPixelEntityType == EntityType.PLACE && entityType == EntityType.PLACE)) {
							this.removePixel();

							// Chama novamente para iniciar o pixel do novo store
							this.initPixel(pixelId, entityType);
							return;
						}

						// Inclui o novo pixel
						fbq('init', pixelId);
					}
					this.pixelAccounts[pixelId] = pixelId;
				}
			}
		} catch (ex) {
			console.error('Error appending PIXEL ID');
			console.error(ex);
		}
	}
	
	private removePixel(): void {
		console.log('Removendo pixel do facebook');
		const pixelElement = document.getElementById('pixel-script');
		if (pixelElement) {
			pixelElement.remove();
		}
		this.pixelFb = null;
		this.firstPixelEntityType = null;
		this.pixelAccounts = {};
	}

}
